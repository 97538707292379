/*  Main page Jsx Styling   */
.addSections{
    /* background-color: aqua; */
    width: 50vw;
    border: 1px dashed rgb(158, 151, 151);
    margin-top: 2vw;
    margin-bottom: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactFollow{
    /* background-color: violet; */
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

/*  Profile Pic .jsx Styling    */

.mainProfile{
    background-color: #FAF1EA;
    border-radius: 2vw
}

.leftSection{
    padding-top: 10vw;
    padding-left: 2vw ;
    padding-bottom: 1vw;
    /* background-color: aqua; */
}

.rightSection{
    display: flex;
    padding-top: 7vw;
    justify-content: flex-end;
    /* background-color: bisque; */
}