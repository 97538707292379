
.profileNav .userHeading .recProfileImg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    /* border: 10px solid rgba(255, 255, 255, 0.751); */
    border: 10px solid #fde2d5;
    display: inline-block;
    /* background: url('https://bootdey.com/img/Content/user-453533-fdadfd.png') no-repeat; */
    /* background-size: cover; */
    /* background-position: center; */
}


.profileNav .userHeading {
    background: #e4b49d;
    color: #fff;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    padding: 30px;
    text-align: center;
}

.profileNav .userHeading.round a {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.3);
    display: inline-block;
}

.profileNav .userHeading a img {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.profileNav .userHeading h1 {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 5px;
}

.profileNav .userHeading p {
    font-size: 12px;
}

.profileNav ul {
    margin-top: 1px;
}

.profileNav ul > li {
    border-bottom: 1px solid #ebeae6;
    margin-top: 0;
    line-height: 30px;
}

.profileNav ul > li:last-child {
    border-bottom: none;
}

.profileNav ul > li > a {
    border-radius: 0;
    -webkit-border-radius: 0;
    color: #89817f;
    border-left: 5px solid #fff;
}

.profileNav ul > li > a:hover,
.profileNav ul > li > a:focus,
.profileNav ul li.active a {
    background: #f8f7f5 !important;
    border-left: 5px solid #e4b49d;
    color: #89817f !important;
}

.profileNav ul > li:last-child > a:last-child {
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
}

.profileNav ul > li > a > i {
    font-size: 16px;
    padding-right: 10px;
    color: #bcb3aa;
}

.rActivity {
    margin: 6px 0 0;
    font-size: 12px;
}

.pTextArea,
.pTextArea:focus {
    border: none;
    font-weight: 300;
    box-shadow: none;
    color: #c3c3c3;
    font-size: 16px;
}

.profileInfo .panelFooter {
    background-color: #f8f7f5;
    border-top: 1px solid #e7ebee;
}

.profileInfo .panelFooter ul li a {
    color: #7a7a7a;
}

.bioGraphHeading {
    background: #e4b49d;
    color: white;
    text-align: center;
    font-style: italic;
    padding: 40px 110px;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    font-size: 16px;
    font-weight: 300;
}

.bioGraphHeading2 {
    background: #e4b49d;
    color: black;
    text-align: center;
    margin-top: 2vw;
    font-style: italic;
    padding: 40px 110px;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    font-size: 16px;
    font-weight: 300;
}
.bioGraphInfo {
    color: #89817e;
}

.bioGraphInfo h1 {
    font-size: 22px;
    font-weight: 300;
    margin: 0 0 20px;
}

.bioRow {
    width: 50%;
    float: left;
    margin-bottom: 10px;
    padding: 0 15px;
}

.bioRow p span {
    width: 100px;
    display: inline-block;
}

.bioChart,
.bioDesk {
    float: left;
}

.bioChart {
    width: 40%;
}

.bioDesk {
    width: 60%;
}

.bioDesk h4 {
    font-size: 15px;
    font-weight: 400;
}

.bioDesk h4.terques {
    color: #4cc5cd;
}

.bioDesk h4.red {
    color: #e26b7f;
}

.bioDesk h4.green {
    color: #97be4b;
}

.bioDesk h4.purple {
    color: #caa3da;
}

.filePos {
    margin: 6px 0 10px 0;
}

.profileActivity h5 {
    font-weight: 300;
    margin-top: 0;
    color: #c3c3c3;
}

.summaryHead {
    background: #ee7272;
    color: #fff;
    text-align: center;
    border-bottom: 1px solid #ee7272;
}

.summaryHead h4 {
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.summaryHead p {
    color: rgba(255, 255, 255, 0.6);
}

ul.summaryList {
    display: inline-block;
    padding-left: 0;
    width: 100%;
    margin-bottom: 0;
}

ul.summaryList > li {
    display: inline-block;
    width: 19.5%;
    text-align: center;
}

ul.summaryList > li > a > i {
    display: block;
    font-size: 18px;
    padding-bottom: 5px;
}

ul.summaryList > li > a {
    padding: 10px 0;
    display: inline-block;
    color: #818181;
}

ul.summaryList > li {
    border-right: 1px solid #eaeaea;
}

ul.summaryList > li:last-child {
    border-right: none;
}

.activity {
    width: 100%;
    float: left;
    margin-bottom: 10px;
}

.activity.alt {
    width: 100%;
    float: right;
    margin-bottom: 10px;
}

.activity span {
    float: left;
}

.activity.alt span {
    float: right;
}

.activity span,
.activity.alt span {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background: #eee;
    text-align: center;
    color: #fff;
    font-size: 16px;
}

.activity.terques span {
    background: #8dd7d6;
}

.activity.terques h4 {
    color: #8dd7d6;
}

.activity.purple span {
    background: #b984dc;
}

.activity.purple h4 {
    color: #b984dc;
}

.activity.blue span {
    background: #90b4e6;
}

.activity.blue h4 {
    color: #90b4e6;
}

.activity.green span {
    background: #aec785;
}

.activity.green h4 {
    color: #aec785;
}

.activity h4 {
    margin-top: 0;
    font-size: 16px;
}

.activity p {
    margin-bottom: 0;
    font-size: 13px;
}

.activity .activityDesk i,
.activity.alt .activityDesk i {
    float: left;
    font-size: 18px;
    margin-right:
    10px;
    color: #bebebe;
}

.activity .activityDesk {
    margin-left: 70px;
    position: relative;
}

.activity.alt .activityDesk {
    margin-right: 70px;
    position: relative;
}

.activity.alt .activityDesk .panel {
    float: right;
    position: relative;
}

.activityDesk .panel {
    background: #f4f4f4;
    display: inline-block;
}

.activity .activityDesk .arrow {
    border-right: 8px solid #f4f4f4 !important;
}

.activity .activityDesk .arrow {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: -7px;
    position: absolute;
    top: 13px;
    width: 0;
}

.activityDesk .arrow-alt {
    border-left: 8px solid #f4f4f4 !important;
}

.activityDesk .arrow-alt {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    right: -7px;
    position: absolute;
    top: 13px;
    width: 0;
}

.activityDesk .album {
    display: inline-block;
    margin-top: 10px;
}

.activityDesk .album a {
    margin-right: 10px;
}

.activityDesk .album a:last-child {
    margin-right: 0px;
}
