
.mainContainer{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}


.btnP{
    font-weight: 600;
    color: #ffffff;
    background-color: #E4B49D;
    border: #E4B49D 3px solid;
    border-radius: 5px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    transition: #E4B49D 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.btnP:active{
    background-color: #ffffff;
    color: #C36C68;
    border: 3px solid #C36C68;
}


.btnF{
    display: none;
}
@media (max-width:991px) {
    .mainContainer > div{
       flex-direction: column;
    }
    .mainContainer >div >div{
        margin-left: 0 !important;
    }
}

@media (max-width:777px) {
    .btnF{
        display: inline-block;
    }
}