
/* @import url("https://fonts.googlepis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

:root {
    --color-primary: #7380ec;
    --color-danger: #ff7782;
    --color-success: #41f1b6;
    --color-warning: #ffbb55;
    --color-white: #fff;
    --color-info-dark: #7d8da1;
    --color-info-light: #dce1eb;
    --color-dark: #363949;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-primary-variant: #111e88;
    --color-dark-variant: #677483;
    --color-background: #f6f6f9;

    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 1.2rem;

    --card-padding: 1.8rem;
    --padding-1: 1.2rem;

    --box-shadow: 0 2rem 3rem var(--color-light)
}

/* * {
    margin: 0;
    padding: 0;
    outline: 0;
    appearance: none;
    border: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
} */

.darkThemeVariables{
    --color-background: #181a1e;
    --color-white:#202528;
    --color-dark: #edeffd;
    --color-dark-variant: #a3bdcc;  
    --color-light: rgba(0, 0, 0, 0.4);
    --box-shadow: 0 2rem 3rem var(--color-light);
}

/* html {
    font-size: 14px;
} */

/* body {
    width: 100vw;
    height: 100vh;
    font-family: poppins, sans-serif;
    font-size: 0.88rem;
    background-color: var(--color-background);
    user-select: none;
    color: var(--color-dark);
    overflow-x: hidden;
} */

.anchorTag{
    color: var(--color-dark);
}

.imageClass {
    display: block;
    width: 100%;
}

.heading1 {
    font-weight: 800;
    font-size: 1.8rem;
}

.heading2 {
    font-weight: 1.4rem;
    font-size: 1.6rem;
}

.heading3 {
    font-size: 0.87rem;
}

/* h4 {
    font-size: 0.8rem;
} */

.heading5 {
    font-size: 0.77rem;
}

/* small {
    font-size: 0.75rem;
} */

.profilePhoto {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
}

.textMuted {
    color: var(--color-info-dark);
}

p {
    color: var(--color-dark-variant);
}

b {
    color: var(--color-dark);
}

.primary {
    color: var(--color-primary);
}

.success {
    color: var(--color-success);
}

.warning {
    color: var(--color-warning);
}

.dashContainer {
    display: grid;
    width: 96%;
    margin: 0 auto;
    /* background-color: red; */
    gap: 1.8rem;
    grid-template-columns: 14rem auto 23rem;
}

.asideComponentDash {
    height: 100vh;
    /* background-color: gray; */
}

.asideComponentDash .top {
    /* background-color: white; */
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}
.asideComponentDash .top span,h2{
    margin-top: 1vw;
}


.asideComponentDash .logo {
    display: flex;
    gap: 0.8rem;
}

.asideComponentDash .logo .imageClass {
    width: 4rem;
    height: 4rem;
}

.asideComponentDash .close {
    display: none;
}

/* Sucidebar */
.asideComponentDash .sidebar {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 86vh;
    position: relative;
    top: 3rem;
}

.asideComponentDash h3 {
    font-weight: 500;
}

.asideComponentDash .sidebar a {
    display: flex;
    color: var(--color-info-dark);
    margin-left: 2rem;
    gap: 1rem;
    align-items: center;
    position: relative;
    height: 3.7rem;
    transition: all 300ms ease;
}

.asideComponentDash .sidebar a span {
    font-size: 1.6rem;
    transform: all 300ms ease;
}

/* .asideComponentDash .sidebar a:last-child {
    position: absolute;
    bottom: 2rem;
    width: 100%;
} */

.asideComponentDash .sidebar a.active {
    background-color: var(--color-light);
    color: var(--color-primary);
    margin-left: 0;
}

.asideComponentDash .sidebar a.active::before {
    content: '';
    width: 6px;
    height: 100%;
    background-color: var(--color-primary);
}

.asideComponentDash .sidebar a.active span {
    color: var(--color-primary);
    margin-left: calc(1rem - 3px);
}

.asideComponentDash .sidebar a:hover {
    color: var(--color-primary);
}

/* .asideComponentDash .sidebar a:hover span{ */
.asideComponentDash .sidebar a:hover {
    margin-left: 1rem;
}

.asideComponentDash .sidebar .messages {
    background-color: var(--color-danger);
    color: var(--color-white);
    padding: 2px 10px;
    font-size: 11px;
    border-radius: var(--border-radius-1);

}

/*  =================================== Main ========================================= */

.mainComp {
    margin-top: 1.4rem;
}

.mainComp .date {
    display: inline-block;
    background-color: var(--color-light);
    border-radius: var(--border-radius-1);
    margin-top: 1rem;
    padding: 0.5rem 1.6rem;
}

.mainComp .date input[type="date"] {
    background-color: transparent;
    color: var(--color-dark);
}

.mainComp .insights {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.6rem;
}

.mainComp .insights>div {
    background-color: var(color--white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    margin-top: 1rem;
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
}

.mainComp .insights>div:hover {
    box-shadow: none;
}


.mainComp .insights>div span {
    background-color: #ff7782;
    padding: 1rem;
    border-radius: 50%;
    color: var(--color-white);
    display: inline-block;
    /* font-size: 2rem; */
}

.mainComp .insights>div.expenses span {
    background-color: var(--color-danger);
}

.mainComp .insights>div.income span {
    background-color: var(--color-success);
}

.mainComp .insights>div.sales span {
    background-color: var(--color-primary);
}

.mainComp .insights>div .middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainComp .insights h3 {
    margin: 1rem 0 0.6rem;
    font-size: 1rem;
}

.mainComp .insights h3 {
    margin: 1rem 0 0.6rem;
    font-size: 1rem;
}

.mainComp .insights .progress {
    position: relative;
    width: 92px;
    height: 92px;
    border-radius: 50%;
}

.mainComp .insights .svgcircleIcon {
    /* background-color: peachpuff; */
    width: 7rem;
    height: 7rem;
}


.mainComp .insights .svgcircleIcon circle {
    fill: none;
    stroke: var(--color-primary);
    stroke-width: 14;
    stroke-linecap: round;
    transform: translate(5px, 5px);
}

.mainComp .insights .expenses .svgcircleIcon circle {
    stroke-dashoffset: -30;
    stroke-dasharray: 200;
}

.mainComp .insights .sales .svgcircleIcon circle {
    stroke-dashoffset: 20;
    stroke-dasharray: 80;
}

.mainComp .insights .income .svgcircleIcon circle {
    stroke-dashoffset: 35;
    stroke-dasharray: 110;
}

.mainComp .insights .progress .number {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainComp .insights small {
    margin-top: 1.3rem;
    display: block;
}

.mainComp .recentOrder {
    margin-top: 2rem;
}

.mainComp .recentOrder h2 {
    margin-bottom: 0.8rem;
}

.mainComp .recentOrder table {
    background-color: var(--color-white);
    width: 100%;
    border-radius: var(--card-border-radius);
    padding: var(--card-padding);
    text-align: center;
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
}

.mainComp .recentOrder table:hover {
    box-shadow: none;
}

.mainComp table tbody td {
    height: 2.8rem;
    border-bottom: 1px solid var(--color-light);
    color: var(--color-dark-variant);
}

.mainComp table tbody tr:last-child td {
    border: none;
}

.mainComp .recentOrder a {
    text-align: center;
    display: block;
    margin: 1rem auto;
}

.right {
    margin-top: 1.4rem;
}

.right .top {
    display: flex;
    justify-content: end;
    gap: 2rem;
    background-color: gray;
}

.right .top button {
    display: none;
}

.right .themeToggler {
    background-color: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
}

.right .themeToggler span {
    font-size: 1.2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right .themeToggler span.active {
    background-color: var(--color-primary);
    color: white;
    border-radius: var(--border-radius-1);
}

.right .top .profile {
    display: flex;
    gap: 2rem;
    text-align: right;
}

.right .recentUpdates h2 {
    margin-bottom: 0.8rem;
}

.right .recentUpdates .updates {
    background-color: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
}

.right .recentUpdates .updates:hover {
    box-shadow: none;
}

.right .recentUpdates .updates .update {
    display: grid;
    grid-template-columns: 2.6rem auto;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* Sales Analytics */
.right .salesAnalytics {
    margin-top: 2rem;
}

.right .salesAnalytics h2 {
    margin-bottom: 0.8rem;
}

.right .salesAnalytics .item {
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.7rem;
    padding: 1.4rem var(--card-padding);
    border-radius: var(--border-radius-3);
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
}

.right .salesAnalytics .item:hover {
    box-shadow: none;
}

.right .salesAnalytics .item .right {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: 0;
    width: 100%;
}

.right .salesAnalytics .item .icon {
    padding: 0.6rem;
    color: var(--color-white);
    border-radius: 50%;
    background-color: var(--color-primary);
    display: flex;
}

.right .salesAnalytics .item.offline .icon {
    background-color: var(--color-danger);
}

.right .salesAnalytics .item.customers .icon {
    background-color: var(--color-success);
}

.right .salesAnalytics .addProduct {
    background-color: transparent;
    border: 2px dashed var(--color-primary);
    color: var(--color-primary);
    display: flex;
    align-items: center;
}


.right .salesAnalytics .addProduct div {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}

.right .salesAnalytics .addProduct div h3 {
    font-weight: 600;
}

@media screen and (max-width:1200px) {
    .dashContainer {
        width: 94%;
        grid-template-columns: 7rem auto 23rem;
    }

    .asideComponentDash .logo h2 {
        display: none;
    }

    .asideComponentDash .sidebar h3 {
        display: none;
    }

    .asideComponentDash .sidebar a {
        width: 5.6rem;
    }

    /* .asideComponentDash a:last-child {
        position: relative;
        margin-top: 1.8rem;
    } */

    .mainComp .insights {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .mainComp .recentOrder {
        width: 94%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin: 2rem 0 0 8.8rem;
    }

    .mainComp .recentOrder table {
        width: 83vw;
    }

    .mainComp table thead tr th:last-child,
    .mainComp table thead tr th:first-child {
        display: none;
    }

    .mainComp table tbody tr td:last-child,
    .mainComp table tbody tr td:first-child {
        display: none;
    }

}

@media screen and (max-width:768px) {
    .dashContainer {
        width: 100%;
        grid-template-columns: 1fr;
    }

    .asideComponentDash {
        position: fixed;
        left: -100%;
        background-color: var(--color-white);
        width: 18rem;
        z-index: 3;
        box-shadow: 1rem 3rem 4rem var(--color-light);
        height: 100vh;
        padding-right: var(--card-padding);
        /* display: none; */
        /* transform: translateX(-100%); */
        animation: showMenu 400ms ease forwards;
        /* transition: transform 400ms ease; */
    }

    @keyframes showMenu {
        0% {
            left: 0;
        }
    }

    .asideComponentDash.show{
        left: 0;
        /* display: block; */
        /* transform: translateX(0); */
    }
    .asideComponentDash.hide{
        left: -100%;
        /* display: none; */
        /* transform: translateX(-100%); */
    }

    .asideComponentDash .logo {
        margin-left: 1rem;
    }

    .asideComponentDash .logo h2 {
        display: inline;
    }

    .asideComponentDash .sidebar h3 {
        display: inline;
    }

    .asideComponentDash .sidebar a {
        width: 100%;
        height: 3.4rem;
    }

    /* .asideComponentDash .sidebar a:last-child {
        position: absolute;
        bottom: 5rem;
    } */

    .asideComponentDash .close {
        display: inline-block;
        cursor: pointer;
    }

    .mainComp {
        margin-top: 8rem;
        padding: 0 1rem;
    }

    .mainComp .recentOrder {
        position: relative;
        margin: 3rem 0 0 0;
        width: 100%;
    }

    .mainComp .recentOrder table {
        width: 100%;
        margin: 0;
    }

    .right {
        width: 94%;
        margin: 0 auto 4rem;
    }

    .right .top {
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        padding: 0 0.8rem;
        height: 4.6rem;
        background-color: var(--color-white);
        width: 100%;
        margin: 0;
        z-index: 2;
        box-shadow: 0 1rem 1rem var(--color-light);
    }

    .right .top .themeToggler {
        width: 4.4rem;
        position: absolute;
        left: 66%;
    }

    .right .profile .info {
        display: none;
    }

    .right .top button {
        display: inline-block;
        background-color: transparent;
        cursor: pointer;
        color: var(--color-dark);
        position: absolute;
        left: 1rem;
        font-size: 2rem;
    }

    .right .top button span {
        font-size: 2rem;
    }
}