.sidebarRec {
    position: sticky;
    top: 0;
    height: 170vh;
    width: 10vw;
    /* overflow: auto; */
    background-color:#e4b49d;
    color: aliceblue;
    border-radius: 0 1vw 1vw 0;
    /* z-index: 100; */
}

@media screen and (max-width: 1100px) {
    .textRec{
        display: none;
    }
    .iconRec{
        margin-left: 0.5vw;
    }
}

@media screen and (max-width: 1024px) {
    .iconRec{
        margin-left: 3vw;
    }
}
@media screen and (max-width: 768px) {
    .iconRec{
        margin-left: 2vw;
    }
}

@media screen and (max-width: 546px) {
    .iconRec{
        margin-left: 0vw;
    }
    .sidebarRec{
        margin-right: 1vw;
    }
}