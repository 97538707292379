body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-control:focus {
  box-shadow: 0 0 5px 2px #e4b49d;
  border-color: #e4b49d;
  outline: none;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.btn:focus {
  box-shadow: 0 0 5px 2px #e4b49d;
  border-color: #e4b49d;
  outline: none;  
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}