/* login Carousel */
.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125%;
    margin-bottom: 20px;
    transition: all 0.5s linear;
  }
  
  .slide {
    border-radius: 0.5rem;
    width: 100%;
    /* height: 300px; */
    transition: all 0.5s linear;
  }
  
  .slide-hidden {
    display: none;
  }
  
  .arrow {
    display: none;
    /* position: absolute;
    filter: drop-shadow(0px 0px 1px rgb);
    width: 2rem;
    height: 1.2rem;
    color: var(--primary-green);
    font-weight: 600; */
  }
  
  .arrow:hover {
    cursor: pointer;
  }
  
  .arrow-left {
    left: -1rem;
  }
  
  .arrow-right {
    right: -1.8rem;
  }
  