* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainContainer {
    display: flex;
}

main {
    padding: 10px;
}

.title {
    font-size: 3rem;
    display: grid;
    place-items: center;
}

.sidebar {
    background: #e4b49d;
    color: white;
    height: 100vh;
    overflow-y: auto;
}

.topSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
}

.logo {
    font-size: 18px;
    line-height: 0;
}

.bars {
    width: 30px;
}

.hide {
    display: none;
}

.search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
}

.search input {
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    background: rgb(238, 238, 238);

    color: white;
}

.routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.link {
    display: flex;
    color: white;
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
    border-right: 4px solid white;
    background: rgb(181, 190, 247);
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
    border-right: 4px solid white;
    background: rgb(181, 190, 247);
}

.link_text {
    white-space: nowrap;
    font-size: 15px;
}

.menu {
    display: flex;
    color: white;

    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
}

.menu_item {
    display: flex;
    gap: 10px;
}

.menu_container {
    display: flex;
    flex-direction: column;
}

.menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
}


