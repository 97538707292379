::-webkit-scrollbar {
    width: 0px;
}

.input-field {
    position: relative;
}

.input-field input {
    /* width: 24vw; */
    height: 45px;
    /* margin: 0px 5px; */
    border-radius: 8px;
    font-size: 14px;
    padding: 0 15px;
    border: 1px solid rgba(154, 158, 161, 0.45);
    background: transparent;
    color: #ec6816;
    outline: none;
}

.input-field label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: rgba(117, 122, 125, 0.45);
    font-size: 19px;
    pointer-events: none;
    transition: 0.3s;
}

input:focus {
    border: 2px solid #FAEFE8;
}

input:focus~label,
input:valid~label {
    top: 0;
    left: 15px;
    font-size: 16px;
    padding: 0 2px;
    background: rgb(255, 255, 255);
}

.signup {
    margin-top: 50px !important;
    width: 60%;
    height: auto;
    margin: auto;

}

.signup-center-box {
    height: 70vh;
    width: 100%;
    box-shadow: 10px 10px 71px 6px rgba(255, 127, 110, 0.52);
    border-radius: 20px;
    margin: auto;
    position: relative;
}

.LogoImg {
    margin: 1rem;
    height: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
}

.googleicon {
    position: absolute;
    top: 50%;
    left: 10px;
    font-size: 25px;
    transform: translate(0, -50%);

}

.curousel_box {
    width: 35%;
    height: 100%;
    background-color: #FAEFE8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px 12px 40px 12px;
}

.box-form {
    width: 65%;
    height: 100%;
    overflow-y: scroll;

}

.box-form form {
    display: flex;
    flex-direction: column;

}

.box-form .form-fields {
    display: flex;
    flex-direction: column;
}

.box-form .form-fields .input-field {
    margin-top: 15px;
}



.box-form .name-container {
    justify-content: space-between;

}

.box-form .password-container {
    justify-content: space-between;
}

.box-form .name-container .input-field {
    width: 49%;
    box-sizing: border-box;


}

.box-form .password-container .input-field {
    box-sizing: border-box;
    width: 49%;
}

.box-form .name-container .input-field input {
    width: 100%;


}

.box-form .password-container .input-field input {
    width: 100%;
}

/* .box-form .input-field{
    width: 70%;
}

.box-form .password-container .input-field{
    width: 48%;
} */

/* .box-form .input-field input{
    width: 100% !important;
    margin: 5px;
} */
.box-form {
    width: 100%;
    justify-content: flex-start !important;
}

.googlebutton__text {
    font-size: 15px;
    margin: 0;
}

@media (max-width: 1250px) {
    .signup {
        width: 70%;
    }

}

@media (max-width: 900px) {
    .signup {
        width: 90%;

    }

    .curousel_box {
        width: 40%;
    }

    .box-form {
        width: 60%;
    }
}


@media (max-width: 670px) {
    .input-field label {
        transform: translateY(-65%);
    }

    .LogoImg {
        position: relative;
        height: 2rem;
    }

    .curousel_box {
        display: none;
    }

    .signup-center-box {
        height: 90vh;
    }

    .box-form {
        width: 80%;
    }
}

@media (max-width: 400px) {
    .box-form {
        width: 92%;
    }

    .input-field label {
        font-size: 15px;
    }

    .googlebutton__text {
        font-size: 12px;
    }
}

@media (max-width: 300px) {
    .flexButton button {
        font-size: 12px;
    }
}